<template>
  <div>
    <!-- Inputs de data -->
    <b-card title="Ações">
      <b-row class="d-flex justify-content-end">
        <b-col sm="6" md="3">
          <b-button variant="primary" @click="getAllPeriod" class="cta" block>
            Todo Período
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Data Inicial">
            <b-form-input
              v-model="start_date"
              @input="getAll"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Data Final">
            <b-form-input
              v-model="end_date"
              @input="getAll"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card :title="`Painel de Usuário `" class="text-left">
      <b-row class="d-flex justify-content-around">
        <div class="dashboard-item text-center">
          <strong>{{ `${item.hours}h` }}</strong>
          <small>Horas no período </small>
        </div>

        <!-- Custo Direto -->

        <div class="dashboard-item text-center">
          <strong>{{ ` ${numberToMonetary(item.directCost)}` }}</strong>
          <small>Custo Direto </small>
        </div>

        <!-- Custo Indireto -->

        <div class="dashboard-item text-center">
          <strong>{{ ` ${numberToMonetary(item.indirectCost)}` }}</strong>
          <small>despesa fixa </small>
        </div>

        <!-- Custo Total -->

        <div class="dashboard-item text-center">
          <strong>{{ ` ${numberToMonetary(item.totalCost)}` }}</strong>
          <small>Custo Total </small>
        </div>
      </b-row>
    </b-card>

    <b-card :title="`Quadro de tarefas`" class="text-left">
      <b-row class="d-flex justify-content-around flex-wrap">
        <div
          class="dashboard-item text-left blur-bg"
          style="
            height: auto;
          "
        >
          <strong style="font-size: 30px">{{
            item && item.totalTasks ? item.totalTasks : 0
          }}</strong>
          <small>Total de tarefas designadas</small>

          <div
            class="dashboard-item-row text-left"
            style="border: 1px #00000055 solid; margin-top: 15px"
          >
            <b-badge variant="danger" class="mr-2">
              🔥 Alta:
              {{
                item && item.taskPriority && item.taskPriority.Alta
                  ? item.taskPriority.Alta
                  : 0
              }}
            </b-badge>
            <b-badge variant="warning" class="mr-2">
              ⚠️ Média:
              {{
                item && item.taskPriority && item.taskPriority.Media
                  ? item.taskPriority.Media
                  : 0
              }}
            </b-badge>
            <b-badge variant="success">
              ⬇️ Baixa:
              {{
                item && item.taskPriority && item.taskPriority.Baixa
                  ? item.taskPriority.Baixa
                  : 0
              }}
            </b-badge>
          </div>

          <div
            class="dashboard-item-row text-left"
            style="border: 1px #00000055 solid; margin-top: 5px"
          >
            <b-badge variant="primary" class="mr-2">
              Ativa:
              {{
                item && item.taskStatus && item.taskStatus['EM EXECUÇÃO']
                  ? item.taskStatus['EM EXECUÇÃO']
                  : 0
              }}
            </b-badge>
            <b-badge variant="primary" class="mr-2">
              Em pausa:
              {{
                item && item.taskStatus && item.taskStatus['EM PAUSA']
                  ? item.taskStatus['EM PAUSA']
                  : 0
              }}
            </b-badge>
            <b-badge variant="primary" class="mr-2">
              Concluída:
              {{
                item && item.taskStatus && item.taskStatus['CONCLUÍDA']
                  ? item.taskStatus['CONCLUÍDA']
                  : 0
              }}
            </b-badge>
          </div>
        </div>

        <div class="dashboard-item text-left" style="width: 55% !important">
          <b-row class="mb-1">
            <b-col
              sm="12"
              lg="3"
              md="12" 
              class="align-items-center p-1 mx-auto mb-1 text-center blur-bg"
            >
              <h3 class="mb-0">{{ todayTasks }}</h3>
              <small>Tarefas para hoje</small>
            </b-col>
            <b-col
              sm="12"
              lg="3"
              md="12"
              class="align-items-center p-1 mx-auto mb-1 text-center blur-bg"
            >
              <h3 class="mb-0">{{ tomorrowTasks }}</h3>
              <small>Tarefas para amanhã</small>
            </b-col>
            <b-col
              sm="12"
              lg="3"
              md="12"
              class="align-items-center p-1 mx-auto mb-1 text-center blur-bg"
            >
              <h3 class="mb-0">{{ lateTask }}</h3>
              <small>Tarefas Atrasadas</small>
            </b-col>
          </b-row>

          <label for="">📋 Tarefas</label>
          <ul class="item-list">
            <li
              v-for="task in taskList"
              :key="task.task_id"
              class="item"
              v-b-tooltip.hover
              :title="
                task && task.project
                  ? `${task.project} - ${
                      task.stage && task.stage.stage_name
                        ? task.stage.stage_name
                        : ''
                    }`
                  : ''
              "
              @click="openModal(task)"
            >
              <strong>
                {{
                  task && task.priority === 'Baixa'
                    ? '⬇️'
                    : task && task.priority === 'Media'
                    ? '⚠️'
                    : task && task.priority === 'Alta'
                    ? '🔥'
                    : '❓'
                }}
                {{
                  task && task.task_name ? task.task_name : 'Tarefa sem nome'
                }}
              </strong>
              <span>
                | Prioridade:
                {{ task && task.priority ? task.priority : 'Desconhecida' }} |
                Status da tarefa:
                {{ task && task.status ? task.status : 'Indefinido' }}
              </span>
            </li>
          </ul>
          <div class="dashboard-item text-left"></div>
        </div>
      </b-row>
    </b-card>

    <!-- DASHBOARDS -->

    <!-- Horas Mes e Custos -->

    <b-card :title="`Dashboard`" class="text-left">
      <b-row class="mb-4">
        <b-col
          v-if="hourPerMonth_data.render"
          cols="12"
          md="6"
          lg="4"
          class="text-center mx-auto"
        >
          <label>Horas X Mês</label>
          <ProfitabilityChart :chartData="hourPerMonth_data"
        /></b-col>

        <b-col
          v-if="costPerMonth_data.render"
          cols="12"
          md="6"
          lg="4"
          class="text-center mx-auto flex"
        >
          <label>Custos (Direto + Indireto)</label>
          <ProfitabilityChart :chartData="costPerMonth_data"
        /></b-col>
      </b-row>
      <b-row v-if="hoursPerProject_data.render">
        <b-col cols="12" md="6" lg="4" class="text-center mx-auto w-10 h-50">
          <label>Horas por projeto</label>
          <ProfitabilityChart :chartData="hoursPerProject_data"
        /></b-col>
        <b-col class="text-center mx-auto w-10 h-50">
          <label>Custo por projeto</label>
          <ProfitabilityChart :chartData="costsPerProject_data"
        /></b-col>
      </b-row>
    </b-card>

    <ModalUpdate
      :_item="eventSelect"
      @closeModal="closeModal"
      :open="modalUpdate"
      :bypassWatch="true"
    />
  </div>
</template>

<script>
import {
  VBTooltip,
  BCol,
  BRow,
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import ProfitabilityChart from '@/components/Chart.vue';
import ModalUpdate from '../../../manager/myTasks/Update.vue';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    BCard,
    ProfitabilityChart,
    BFormInput,
    BFormGroup,
    BButton,
    BBadge,
    ModalUpdate,
  },
  data: () => ({
    allPeriod: false,
    eventSelect: null,
    modalUpdate: false,
    bypassWatch: false,
    taskList: [],
    item: {
      directCost: 0,
      indirectCost: 0,
      totalCost: 0,
      hours: 0,
      projectResumeDashboard: { projectCost: 0 },
    },
    project_name: 'Projeto',
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),

    hourPerMonth_data: { render: false },
    costPerMonth_data: { render: false },

    hoursPerProject_data: { render: false },
    costsPerProject_data: { render: false },

    chartOptions: {
      chart: {
        id: 'bar',
        type: 'bar',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (number) {
            if (isNaN(number)) {
              return 'Invalid input';
            }
            if (number === 0) {
              return 'R$ 0,00';
            }
            if (number < 100) {
              const formattedCents = number.toFixed(2).padStart(5, '0');
              return `0,${formattedCents}`;
            }
            const [integerPart, decimalPart] = (number / 100)
              .toFixed(2)
              .toString()
              .split('.');
            const formattedIntegerPart = integerPart.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            );
            const result = ` ${formattedIntegerPart},${decimalPart}`;
            return result;
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 40,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (number) {
          if (isNaN(number)) {
            return 'Invalid input';
          }
          if (number === 0) {
            return 'R$ 0,00';
          }
          if (number < 100) {
            const formattedCents = number.toFixed(2).padStart(5, '0');
            return `0,${formattedCents}`;
          }
          const [integerPart, decimalPart] = (number / 100)
            .toFixed(2)
            .toString()
            .split('.');
          const formattedIntegerPart = integerPart.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ','
          );
          const result = ` ${formattedIntegerPart},${decimalPart}`;
          return result;
        },
        offsetY: -20,
        style: {
          fontSize: '10px',
          colors: ['#304758'],
        },
      },
    },

    chartOptions_line: {
      chart: {
        id: 'bar',
        type: 'area',
        stacked: true,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'top',
          },
          columnWidth: 20,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
    },
  }),

  created() {
    this.getAll();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    currentUser() {
      return this.$store.getters.user.accesses.find(
        (a) => a.workspace_id == this.$store.getters.currentWorkspace.id
      );
    },
    todayTasks() {
      const today = new Date().toISOString().split('T')[0];
      return this.taskList.filter((task) => task.date.split('T')[0] === today && task.status === 'EM EXECUÇÃO')
        .length;
    },

    tomorrowTasks() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];

      return this.taskList.filter(
        (task) => task.date.split('T')[0] === tomorrowStr && task.status === 'EM EXECUÇÃO'
      ).length;
    },

    lateTask() {
      const today = new Date().toISOString().split('T')[0];
      return this.taskList.filter(
        (task) =>
          task.date.split('T')[0] < today && task.status === 'EM EXECUÇÃO'
      ).length;
    },
  },

  methods: {
    openModal(task) {
      this.eventSelect = task;
      this.modalUpdate = true;
    },
    closeModal() {
      this.modalUpdate = false;
    },
    async getAllPeriod() {
      this.allPeriod = true;

      const anoAtual = new Date().getFullYear();
      const startDate = new Date(anoAtual, 0, 1);
      const formattedStartDate = startDate.toISOString().split('T')[0];

      const endDate = new Date(anoAtual, 11, 31);
      const formattedEndDate = endDate.toISOString().split('T')[0];

      this.start_date = formattedStartDate;
      this.end_date = formattedEndDate;

      this.$toast.success('Selecionado todo o periodo.', {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      });

      await this.getAll();
      this.allPeriod = false;
    },

    async getAll() {
      let commom_user_id = '';

      if (
        !this.currentUser.is_admin &&
        !this.currentUser.is_manager &&
        !this.currentUser.is_transaction_manager
      ) {
        commom_user_id = this.currentUser.id;
      }

      this.$store
        .dispatch('getUserDashboard', {
          start_date: this.start_date,
          end_date: this.end_date,
          id: this.$route.params.id ? this.$route.params.id : commom_user_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
          allPeriod: this.allPeriod == true ? true : false,
        })
        .then(async (resp) => {
          await this.setHoursPerMonth(resp.hoursMonth);
          await this.setCostsPerMonth(resp.costsMonth);
          await this.setHoursPerProject(resp.projectHours);
          await this.setCostPerProject(resp.projectCosts);

          this.item = resp;
          this.taskList = resp.taskList;

          console.log(' this.taskList', this.taskList);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async setHoursPerMonth(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.hours);
        date.push(this.dateFormatter(e.month, 'MM/yy'));
      });

      this.hourPerMonth_data = {
        render: true,
        chartOptions: {
          ...this.chartOptions,

          yaxis: {
            labels: {
              formatter: function (e) {
                return e + 'h';
              },
            },
          },
          dataLabels: {
            offsetY: -20,
            style: {
              fontSize: '10px',
              colors: ['#304758'],
            },
            formatter: function (e) {
              return e + 'h';
            },
          },
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas no mês',
            data,
          },
        ],
      };
    },

    async setCostsPerMonth(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.cost);
        date.push(this.dateFormatter(e.month, 'MM/yy'));
      });

      this.costPerMonth_data = {
        render: true,
        chartOptions: {
          ...this.chartOptions,

          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas no mês',
            data,
          },
        ],
      };
    },

    async setHoursPerProject(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.hours);
        date.push(e.project);
      });

      this.hoursPerProject_data = {
        render: true,
        width: '100%',
        height: 300,
        chartOptions: {
          chart: {
            type: 'bar',
            // height: 350,
          },
          plotOptions: {
            bar: {
              distributed: true,
              borderRadius: 4,
              horizontal: true,
              columnWidth: '900px',
            },
            dataLabels: {
              maxItems: 2,
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },

    async setCostPerProject(props) {
      const data = [];
      const date = [];
      props.map((e) => {
        data.push(e.cost);
        date.push(e.project);
      });

      this.costsPerProject_data = {
        render: true,
        width: '100%',
        height: 300,
        chartOptions: {
          chart: {
            type: 'bar',

            // height: 350,
          },
          plotOptions: {
            bar: {
              distributed: true,
              borderRadius: 4,
              horizontal: true,
              columnWidth: '100px',
            },
          },
          dataLabels: {
            formatter: function (number) {
              if (isNaN(number)) {
                return 'Invalid input';
              }
              if (number === 0) {
                return 'R$ 0,00';
              }
              if (number < 100) {
                const formattedCents = number.toFixed(2).padStart(5, '0');
                return `0,${formattedCents}`;
              }
              const [integerPart, decimalPart] = (number / 100)
                .toFixed(2)
                .toString()
                .split('.');
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              );
              const result = ` ${formattedIntegerPart},${decimalPart}`;
              return result;
            },
            enabled: true,
          },
          // yaxis: {
          //   labels: {
          //     formatter: function (number) {
          //       if (isNaN(number)) {
          //         return 'Invalid input';
          //       }
          //       if (number === 0) {
          //         return 'R$ 0,00';
          //       }
          //       if (number < 100) {
          //         const formattedCents = number.toFixed(2).padStart(5, '0');
          //         return `0,${formattedCents}`;
          //       }
          //       const [integerPart, decimalPart] = (number / 100)
          //         .toFixed(2)
          //         .toString()
          //         .split('.');
          //       const formattedIntegerPart = integerPart.replace(
          //         /\B(?=(\d{3})+(?!\d))/g,
          //         ','
          //       );
          //       const result = ` ${formattedIntegerPart},${decimalPart}`;
          //       return result;
          //     },
          //   },
          // },
          xaxis: {
            labels: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100)
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = ` ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
            categories: date,
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-item {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
}
.dashboard-item-row {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
}
.selected-items-container {
  width: 100%;
}

.blur-bg {
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.item-list {
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  padding: 0.5rem;
  height: 10rem;
  overflow-y: auto;

  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Firefox */

  &::-webkit-scrollbar {
    width: 6px; /* Reduz a largura */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Cor da alça da rolagem */
    border-radius: 3px; /* Cantos arredondados */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Fundo da barra */
  }

  .placeholder {
    font-style: italic;
    color: #888;
  }

  .item {
    position: relative;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 0.25rem;
    background-color: #f9f9f9;
    font-size: 10px;
    color: #000;

    &:hover {
      background-color: #e9e9e9;
    }

    .pill {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      background-color: #e74c3c;
      color: #fff;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #c0392b;
      }
    }
  }
}
</style>
